import React, { useState, useEffect, useContext } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";
import AppRouter from "./AppRouter";
import AuthRouter from "./authentication/AuthRouter";

const Mainrouter = () => {
    const { state, dispatch } = useContext(Context);
    const [isLoading, setLoading] = useState(true);

    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);

    useEffect(() => {
        async function fetchUserData() {
            let promise = new Promise((resolve, reject) => {
                let user_data = localStorage.getItem("user_data");
                user_data = JSON.parse(user_data);

                dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });

                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                }, 500);
            });

            let result = await promise;
        }

        fetchUserData();
    }, []);

    useEffect(() => {
        dispatch({
            type: "UPDATE_SEARCH_PARAMS",
            payload: currentParams,
        });
    }, []);

    return isLoading ? (
        <SectionLoader />
    ) : (
        <Routes>
            <Route
                path="auth/*"
                element={
                    <AuthRoute>
                        <AuthRouter />
                    </AuthRoute>
                }
            />
            <Route
                path="/*"
                element={
                    <PrivateRoute>
                        <AppRouter />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

export default Mainrouter;
