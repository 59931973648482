import React, { Suspense, useState, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SectionLoader from "../../helpers/SectionLoader";
const CampusSingle = lazy(() => import("../../includes/tefun/CampusSingle"));
const Sidebar = lazy(() => import("../../includes/Sidebar"));
const Topbar = lazy(() => import("../../includes/Topbar"));
const Tefun = lazy(() => import("../../screens/Tefun"));

const AppRouter = () => {
    const [isExpand, setExpand] = useState(false);

    return (
        <Suspense fallback={<SectionLoader />}>
            <>
                <Sidebar setExpand={setExpand} />
                <Topbar isExpand={isExpand} setExpand={setExpand} />
                <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    {/* <Route
                        path="/dashboard/students/"
                        element={<Navigate to="/students" />}
                    /> */}
                    <Route path="/dashboard" element={<CampusSingle />} />
                    <Route path="campus/students/" element={<Tefun />} />
                </Routes>
            </>
        </Suspense>
    );
};

export default AppRouter;
